/* .ant-input-affix-wrapper {
  border-color: #00b96b;
} */

.ant-input-affix-wrapper:focus {
  border-color: #00b96b;
  box-shadow: 0 0 0 2px rgba(0,0,0, .2);
}

.ant-input-affix-wrapper:hover {
  border-color: #00b96b;
  /* box-shadow: 0 0 0 2px rgba(0,0,0, .2); */
}
